<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
       قسم الصفحة الرئيسية
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
       
          <b-col md="2">
            <label class="flex">السلايدر الرئيسي</label>
           </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.slider"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col>
         <b-col md="2">
            <label>البحث</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.search"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col>
           <b-col md="2">
            <label>الدورات القادمة </label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.upcoming"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>الفئات</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.categories"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>عن LPC</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.about"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>سلايدر المدن</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.cities"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>الشهادات</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.certificate"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>المقال</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.articles"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col>
          <b-col md="2">
            <label>العملاء</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.clients"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> اخفاء</b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col> <b-col md="2">
            <label>الاعتمادات</label>
          </b-col> <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.accreditations"
              label="saveas"
              class="mb-2"
            >
             
                <b-form-radio value="show" > عرض </b-form-radio>
                <b-form-radio value="hide"> hide </b-form-radio>
              
               
            
            </b-form-radio-group>
          
          </b-col>
         
         
          
      
          
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
            حفظ
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>
 
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {

  
    const form=ref({ slider: "",
      search: "",
      upcoming: "",
      categories: '',
      about: '',
      cities: "",
      certificate: "",
      articles: "",
      clients: "",
      accreditations: "",})
      const addCourseForm = reactive(form);
     store.dispatch("setting/GetHomeSection").then((response) => {
  console.log("GetHomeSection",response)
  form.value=response.data

    });
   
     const addCourseFormvalidate=ref()

    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("slider", addCourseForm.value.slider);
formData.append("search", addCourseForm.value.search);
formData.append("upcoming", addCourseForm.value.upcoming);
formData.append("categories", addCourseForm.value.categories);
formData.append("about", addCourseForm.value.about);
formData.append("cities", addCourseForm.value.cities);
formData.append("articles", addCourseForm.value.articles);
formData.append("clients", addCourseForm.value.clients);
formData.append("certificate", addCourseForm.value.certificate);
formData.append("accreditations", addCourseForm.value.accreditations);

      console.log("formData",formData)
           store.dispatch('setting/HomeSection',formData)
        .then(response => {
           Vue.swal({
            icon: "success",
              title: "تم التحديث",
              confirmButtonText: "حسنا",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    
    return {
     
      addCourseForm,

      addCourseFormvalidate,
   
      form,

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
          BTable,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
